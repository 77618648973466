body {
    margin: 0;
    padding: 0;
}

#loadingContainer {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .3);
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

#loadingContainer #icon_spinner {
    position: relative;
    z-index: 2;
    animation: rotator 1.4s linear infinite;
    margin: 5px;
    width: 66px;
    height: 66px;
}

#loadingContainer #icon_spinner .path {
    animation: dash 1.4s ease-in-out infinite;
    stroke: #2ea6f8;
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
}

#loadingContainer.hide {
    display: none;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 187;
    }
    50% {
        stroke-dashoffset: 46.75;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform: rotate(450deg);
    }
}